import { Control, Controller, FieldErrors } from "react-hook-form"
import { ContactRequest } from "@/lib/aspida/api/@types"
import React, { FC } from "react"
import { FormInput } from "@/components/form/FormInput"
import { outputFormErrorText } from "@/functions/outputFormErrorText"
import { contactParams } from "@/components/pages/Contact/params"

type CustomInput = {
    className?: string
    name: keyof ContactRequest
    control: Control<ContactRequest>
    errors: FieldErrors<ContactRequest>
    isDisplayTextCounter?: boolean
    disabled?: boolean
}
export const CustomInput: FC<CustomInput> = ({
    className,
    name,
    control,
    errors,
    isDisplayTextCounter,
    disabled,
}) => {
    const targetParam = contactParams[name]
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={targetParam.defaultValue}
            rules={targetParam.validationConf}
            render={({ field }) => (
                <>
                    <FormInput
                        {...field}
                        className={`u-width-100 ${className || ""}`}
                        required={!!targetParam.validationConf.required}
                        errorText={
                            errors?.[name]?.type &&
                            outputFormErrorText(
                                errors?.[name]?.type || "",
                                targetParam.validationConf
                            )
                        }
                        label={targetParam.label}
                        multiline={targetParam.multiline}
                        placeholder={targetParam.placeholder}
                        Icon={targetParam.Icon}
                        example={targetParam.example}
                        disabled={disabled}
                        textCounter={
                            isDisplayTextCounter
                                ? {
                                      maxLength:
                                          typeof targetParam.validationConf
                                              .maxLength === "number"
                                              ? targetParam.validationConf
                                                    .maxLength
                                              : targetParam.validationConf
                                                    .maxLength?.value || 0,
                                  }
                                : undefined
                        }
                    />
                </>
            )}
        />
    )
}
