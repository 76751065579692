export default {
    error: {
        nothingContactURL: '内容送信先のURLが設定されていません',
        server: {
            submit: 'ただいまメールをお送りすることができません。\n申し訳ございませんが、しばらく時間がたった後再度送信の程よろしくお願いいたします。',
            notFound: '設定されたURLが見つかりません。'
        }
    },
    notice: {
        mailApiNotAvailable: "ただいまサーバ障害中のためお問い合わせメールを送信することができません。\n恐れ入りますが、時間を空けてもう一度アクセスしていただき再度お問い合わせをお願いいたします。"
    },
    info: {}
}