import React, { FC, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import BtnKakuLoadAnime from "@/components/btn/BtnKakuLoadAnime"
import { Head } from "@/components/Head"
import { kaigyoToBr } from "@/functions/kaigyoToBr"
import messagesParam from "@/params/messagesParam"
import { GlobalLayout } from "@/components/GlobalLayout"
import { CustomInput } from "@/components/pages/Contact/Contact.component"
import { navigate } from "gatsby"
import { ContactRequest } from "@/lib/aspida/api/@types"
import { useHealthCheckApi } from "@/hooks/api/useHealthCheckApi"
import { useContactApi } from "@/hooks/api/useContactApi"

export const Contact: FC = () => {
    const { healthCheckApiState, healthCheck } = useHealthCheckApi()
    const { contactApiState, submit } = useContactApi()
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const {
        handleSubmit,
        formState: { errors, isValid },
        control,
    } = useForm<ContactRequest>({
        mode: "onBlur",
        reValidateMode: "onChange",
    })

    useEffect(() => {
        healthCheckEmailApi()
    }, [])

    const onSubmitButtonClick = useCallback(() => {
        if (!isValid) {
            alert("必須項目を満たしてから内容確認を行ってください")
        }
    }, [isValid])

    const onSubmit: SubmitHandler<ContactRequest> = useCallback(
        async (data) => {
            if (!confirm("お問合せ内容を送信します。\nよろしいですか？")) {
                return
            }

            await submit(data, {
                onSuccess: () => {
                    // リダイレクト
                    navigate("/contact/send", {
                        state: {
                            isSuccess: true,
                            type: "contact",
                            toAddress: data.toAddress,
                        },
                    })
                },
                onFailure: () => {
                    alert(
                        "ただいまメールをお送りすることができません。\n申し訳ございませんが、しばらく時間がたった後再度送信の程よろしくお願いいたします。"
                    )
                },
            })
        },
        [isValid]
    )

    /**
     * apiのヘルスチェックを行う
     */
    const healthCheckEmailApi = useCallback(async () => {
        await healthCheck({
            onFailure: () => {
                setIsDisabled(true)
            },
        })
    }, [])

    return (
        <GlobalLayout Head={<Head title="contact" />}>
            {/* ヘルスチェック結果が通らなかった場合に利用できない旨のエラー出力 */}
            {!healthCheckApiState && (
                <div className="c-notice-page-error">
                    <div
                        className="c-notice-page-error__content"
                        dangerouslySetInnerHTML={{
                            __html: kaigyoToBr(
                                messagesParam.notice.mailApiNotAvailable
                            ),
                        }}
                    />
                </div>
            )}

            <section className="p-section u-mb-100px">
                <h2 className="p-section__title u-mb-50px">
                    お問合せ
                    <p className="p-section__sub-title">
                        必須項目を記載の上、送信をお願いします
                    </p>
                </h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-form u-mr-3 u-ml-3 p-section__content"
                >
                    <CustomInput
                        className="u-mb-10"
                        name={"name"}
                        control={control}
                        errors={errors}
                        disabled={isDisabled}
                    />
                    <CustomInput
                        className="u-mb-10"
                        name={"toAddress"}
                        control={control}
                        errors={errors}
                        disabled={isDisabled}
                    />
                    <CustomInput
                        className="u-mb-10"
                        name={"subject"}
                        control={control}
                        errors={errors}
                        disabled={isDisabled}
                    />
                    <CustomInput
                        className="u-mb-10"
                        name={"content"}
                        control={control}
                        errors={errors}
                        isDisplayTextCounter={true}
                        disabled={isDisabled}
                    />

                    <div className="p-form__submit">
                        <BtnKakuLoadAnime
                            link="#"
                            type="submit"
                            isActive={contactApiState.isLoading}
                            onClick={onSubmitButtonClick}
                            disabled={isDisabled}
                        >
                            送信する
                        </BtnKakuLoadAnime>
                    </div>
                </form>
            </section>
        </GlobalLayout>
    )
}
