import messagesParam from "@/params/messagesParam"
import { client } from "@/lib/aspida/client"
import { useApiRequest } from "@/hooks/api/useApiRequest"
import { SuccessResponse } from "@/lib/aspida/api/@types"

type HealthCheckOption = {
    /**
     * 成功時の処理フック
     */
    onSuccess?: () => void
    /**
     * 失敗時の処理フック
     */
    onFailure?: () => void
}

/**
 * health check API hook
 * @returns ヘルスチェックAPIモジュール
 */
export const useHealthCheckApi = () => {
    const { request, apiRequestState } = useApiRequest<SuccessResponse>()

    return {
        healthCheck: async (option?: HealthCheckOption) => {
            await request(async () => {
                if (!process.env.GATSBY_API_ENDPOINT) {
                    throw new Error(messagesParam.error.nothingContactURL)
                }

                const res = await client.v1.healthCheck.get()

                if (res.status !== 200) {
                    throw new Error("API利用不可状態です")
                }

                return res
            }, option)
        },
        healthCheckApiState: apiRequestState,
    }
}
