import { client } from "@/lib/aspida/client";
import { ContactRequest, SuccessResponse } from "@/lib/aspida/api/@types";
import { RequestOption, useApiRequest } from "@/hooks/api/useApiRequest";

/**
 * contact API hook
 * @returns お問合せAPIモジュール
 */
export const useContactApi = () => {
    const { request, apiRequestState } = useApiRequest<SuccessResponse>()

    return {
        submit: async (
            data: ContactRequest,
            option?: RequestOption<SuccessResponse>
        ) => {
            await request(async () => {
                return client.v1.contact.post({
                    body: data,
                })
            }, option)
        },
        contactApiState: apiRequestState,
    }
}
