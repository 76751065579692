import { ContactRequest } from "@/lib/aspida/api/@types"
import { FormAttributes } from "@/types/form/FormAttributes"
import { FaEnvelope, FaPencilAlt, FaUserCircle } from "react-icons/fa"

export const contactParams: Record<keyof ContactRequest, FormAttributes> = {
    name: {
        label: "お名前",
        placeholder: "お名前を入力してください",
        type: "text",
        example: "(例)ユビオリ　タロウ",
        Icon: FaUserCircle,
        validationConf: {
            required: true,
            minLength: 1,
            maxLength: 255,
        },
    },
    toAddress: {
        label: "メールアドレス",
        type: "email",
        placeholder: "メールアドレスを入力してください",
        example: "(例)example@example.com",
        Icon: FaEnvelope,
        validationConf: {
            minLength: 1,
            maxLength: 255,
            required: true,
            pattern: new RegExp(
                /^[A-Za-z0-9\-._!#$%&'*+\/=?^`{|}~"]+@([A-Za-z0-9\-_]+\.){1,2}[A-Za-z]+$/
            ),
        },
    },
    subject: {
        label: "件名",
        type: "text",
        placeholder: "お問合せ件名を入力してください",
        Icon: FaPencilAlt,
        validationConf: {
            required: true,
            minLength: 1,
            maxLength: 255,
        },
    },
    content: {
        label: "お問合せ内容",
        type: "text",
        placeholder: "お問合せ内容を入力してください",
        Icon: FaPencilAlt,
        multiline: true,
        validationConf: {
            required: true,
            minLength: 1,
            maxLength: 1900,
        },
    },
}
